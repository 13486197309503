import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import Preloader from "./Preloader"; // Assuming Preloader is used for loading state
import Header from "./Header";
import Footer from "./Footer";

const BookAppointment = () => {
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  //Fetching services from Strapi// 
  useEffect(() => {
      const fetchServices = async () => {
        try {
          const response = await fetch("http://localhost:1337/api/services?populate=Category");
          const data = await response.json();
          
          if (data?.data) {
            setServices(data.data);
          } else {
            console.error("Unexpected API response:", data);
          }
        } catch (error) {
          console.error("Error fetching services:", error);
        }
      };
  
      fetchServices();
    }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Do something with the data (e.g., submit to API or save locally)
    const appointmentData = {
      service: selectedService,
      date,
      time,
      name,
      phone,
      email,
    };
    console.log("Appointment Data:", appointmentData);
    // Reset form after submission or show success message
  };

  return (
    <div>
      <Header />
      <div className="book-appointment-page">
        {loading && <Preloader setLoading={setLoading} />}
        <Element name="home" className="section bg-gray-101"style={{ borderBottom: '3px solid rgb(102, 88, 10)'}}>
          <div className="home-section">
            <video
              autoPlay
              loop
              muted
              playsInline
              className="background-video"
            >
              <source src="/Alec The Barber.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="text-content">
              <h1>Book Now</h1>
              <p>Your go-to spot for a fresh cut and style!</p>
              <div className="buttons">
                <button className="Services-button">
                  <Link to="/">Home</Link>
                </button>
                <button className="Book Now">
                  <Link to="/Services">Services</Link>
                </button>
              </div>
            </div>
          </div>
        </Element>

        <Element name="book-appointment" className="section">
          <div className="book-appointment-section">
            <h1>Book Your Appointment</h1>
            <p>Select your service, date, and time to book your appointment.</p>

            <form onSubmit={handleSubmit} className="appointment-form">
              <div className="form-group">
                <label htmlFor="service">Select Service</label>
                <select
                  id="service"
                  value={selectedService}
                  onChange={(e) => setSelectedService(e.target.value)}
                  required
                >
                  <option value="">Choose a service</option>
                  {services.map((service) => (
                    <option key={service.id} value={service.Name}>
                      {service.Name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="date">Select Date</label>
                <input
                  type="date"
                  id="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="time">Select Time</label>
                <input
                  type="time"
                  id="time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="name">Your Name</label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="phone">Your Phone</label>
                <input
                  type="text"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Your Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <button type="submit" className="submit-button">
                Book Appointment
              </button>
            </form>
          </div>
        </Element>
      </div>
      <Footer />
    </div>
  );
};

export default BookAppointment;