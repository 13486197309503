import React from "react";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import { FaInstagram, FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
import { useLocation, Link as RouterLink } from "react-router-dom";  // Import useLocation

const Footer = () => {
  const location = useLocation(); // Get the current route

  // Check if the current route is the homepage
  const isHomepage = location.pathname === "/";

  return (
    // Conditionally apply motion to the footer if it's the homepage
    isHomepage ? (
      <motion.footer
        className="footer bg-black text-white"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        <div className="footer-logo-container">
          <img src={"Logo.webp"} alt="logo" className="footer-logo" />
        </div>
        <div className="footer-container">
          {/* Left Section - Business Info */}
          <div className="footer-brand">
            <h2>Location</h2>
            <p>
              <FaMapMarkerAlt /> 7316 E. Stetson Dr. Scottsdale, Arizona, 85251
              Suite #8
            </p>
            <p>
              <FaPhoneAlt /> (480) 822-8851
            </p>
          </div>

          {/* Middle Section - Quick Links */}
          <div className="footer-links">
            <h3>Quick Links</h3>
            <ul>
            <li>
          {isHomepage ? (
            <Link to="home" smooth={true} duration={500}>
              Home
            </Link>
          ) : (
            <RouterLink to="/">Home</RouterLink> // When not on the homepage, it routes back to the homepage
          )}
        </li>
        <li>
          {isHomepage ? (
            <Link to="services" smooth={true} duration={500}>
              Services
            </Link>
          ) : (
            <RouterLink to="/services">Services</RouterLink> // Navigates to services page when not on the homepage
          )}
        </li>
        <li>
          {isHomepage ? (
            <Link to="book-appointment" smooth={true} duration={600}>
              Book Appointment
            </Link>
          ) : (
            <RouterLink to="https://firststopbarbershop.glossgenius.com/">Book Appointment</RouterLink> // Routes to the booking page when not on homepage
          )}
        </li>
        <li>
          {isHomepage ? (
            <Link to="about" smooth={true} duration={500}>
              About Us
            </Link>
          ) : (
            <RouterLink to="/about">About Us</RouterLink> // Routes to About Us page when not on homepage
          )}
        </li>
        {isHomepage && (
          <li>
            <Link to="client-photos" smooth={true} duration={500}>
              Client Photos
            </Link>
          </li>
        )}
            </ul>
          </div>

          {/* Right Section - Social Media */}
          <div className="footer-social">
            <h3>Follow Us</h3>
            <div className="social-icons">
              <a
                href="https://www.instagram.com/first_stop_barbershop__?igsh=MW5zdDg1Z2Z5MWZ6cg%3D%3D&utm_source=qr&fbclid=PAZXh0bgNhZW0CMTEAAaZhQfGl8eheKKFgBptbfXpoG1kmGlsdWoputd_36MVk7cRx8NaaAu1QKtU_aem_D_4u9k7dNyPS-I2b4TMvrw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="footer-bottom">
          <p>© {new Date().getFullYear()} First Stop. All Rights Reserved.</p>
        </div>
      </motion.footer>
    ) : (
      <footer className="footer bg-black text-white">
        <div className="footer-logo-container">
          <img src={"Logo.webp"} alt="/Logo2.webp" className="footer-logo" />
        </div>
        <div className="footer-container">
          {/* Left Section - Business Info */}
          <div className="footer-brand">
            <h2>Location</h2>
            <p>
              <FaMapMarkerAlt /> 7316 E. Stetson Dr. Scottsdale, Arizona, 85251
              Suite #8
            </p>
            <p>
              <FaPhoneAlt /> (480) 822-8851
            </p>
          </div>

          {/* Middle Section - Quick Links */}
          <div className="footer-links">
            <h3>Quick Links</h3>
            <ul>
            <li>
          {isHomepage ? (
            <Link to="home" smooth={true} duration={500}>
              Home
            </Link>
          ) : (
            <RouterLink to="/">Home</RouterLink> // When not on the homepage, it routes back to the homepage
          )}
        </li>
        <li>
          {isHomepage ? (
            <Link to="services" smooth={true} duration={500}>
              Services
            </Link>
          ) : (
            <RouterLink to="/services">Services</RouterLink> // Navigates to services page when not on the homepage
          )}
        </li>
        <li>
          {isHomepage ? (
            <Link to="book-appointment" smooth={true} duration={600}>
              Book Appointment
            </Link>
          ) : (
            <RouterLink to="https://firststopbarbershop.glossgenius.com/">Book Appointment</RouterLink> // Routes to the booking page when not on homepage
          )}
        </li>
        <li>
          {isHomepage ? (
            <Link to="about" smooth={true} duration={500}>
              About Us
            </Link>
          ) : (
            <RouterLink to="/about">About Us</RouterLink> // Routes to About Us page when not on homepage
          )}
        </li>
        {isHomepage && (
          <li>
            <Link to="client-photos" smooth={true} duration={500}>
              Client Photos
            </Link>
          </li>
        )}
            </ul>
          </div>

          {/* Right Section - Social Media */}
          <div className="footer-social">
            <h3>Follow Us</h3>
            <div className="social-icons">
              <a
                href="https://www.instagram.com/first_stop_barbershop__?igsh=MW5zdDg1Z2Z5MWZ6cg%3D%3D&utm_source=qr&fbclid=PAZXh0bgNhZW0CMTEAAaZhQfGl8eheKKFgBptbfXpoG1kmGlsdWoputd_36MVk7cRx8NaaAu1QKtU_aem_D_4u9k7dNyPS-I2b4TMvrw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="footer-bottom">
          <p>© {new Date().getFullYear()} First Stop. All Rights Reserved.</p>
        </div>
      </footer>
    )
  );
};

export default Footer;