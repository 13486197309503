import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Element } from "react-scroll";
import Header from "./components/Header";
import "./styles/styles.css";
import Preloader from "./components/Preloader";
import Footer from "./components/Footer";
import { Link } from "react-router-dom";
import Slider from "react-slick"; 

const fadeInVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const settings = {
  dots: false, // Show navigation dots
  infinite: true, // Enable infinite looping
  speed: 4000, // Speed of the transition between slides (in milliseconds)
  slidesToShow: 3, // Number of slides to show at once
  slidesToScroll: 1, // Number of slides to scroll at a time
  arrows: false, // Optionally hide the arrows if not needed
  autoplay: true, // Enable autoplay
  autoplaySpeed: 0, // Set autoplay speed to 0 for continuous movement
  cssEase: "linear", // Ensure smooth and continuous transition
  pauseOnHover: false, // Prevent the carousel from pausing when hovering over it
};

function Homepage() {
    const [loading, setLoading] = useState(true);
    const [photos, setPhotos] = useState([]);


    useEffect(() => {
      const fetchPhotos = async () => {
        try {
          const response = await fetch("https://decisive-health-361bf7e6f0.strapiapp.com/api/client-photos?populate=Photos");
          const data = await response.json();
      
          console.log("Fetched data", data); // Log the full response to inspect the structure
      
          if (data?.data) {
            // Process the photos from the array of Photos in each entry
            const photos = data.data.flatMap((entry) => {
              console.log("Entry:", entry); // Log each entry to inspect its structure
              
              // Check if Photos exist in the entry
              if (entry?.Photos?.length > 0) {
                // Map through each photo in the Photos array and extract the URL
                return entry.Photos.map((photo) => {
                  if (photo?.url) {
                    return {
                      id: photo.id,
                      imageUrl: `${photo.url}`, // Full URL for the image
                      name: photo.name,
                    };
                  } else {
                    console.error("No photo URL found for photo:", photo); // Log any missing URLs
                    return null; // Return null if no photo URL found
                  }
                }).filter(Boolean); // Filter out null values (entries without valid photo URLs)
              } else {
                console.error("No photos in entry:", entry);
                return []; // Return an empty array if no photos exist
              }
            }).flat(); // Flatten the array in case there are multiple entries with multiple photos
      
            setPhotos(photos); // Set the photos state
          } else {
            console.error("Unexpected API response:", data);
          }
        } catch (error) {
          console.error("Error fetching Photos:", error);
        }
      };
    
        fetchPhotos();
      }, []);
      
  return (
    <div>
            {loading && <Preloader setLoading={setLoading} />} {/* Show preloader while loading */}
      <Header />

      <Element name="home" className="section bg-gray-101">
        <motion.div
          className="home-section"
          initial="visible"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeInVariants}
        >
           <video autoPlay loop muted playsInline className="background-video">
            <source src="/Alec The Barber.mp4" type="video/mp4" />
              Your browser does not support the video tag.
          </video>
          <div className="text-content">
            <h1>First Stop</h1>
            <p>Your go-to spot for a fresh cut and style!</p>
            <div className="buttons">
              <button className="Services-button">
              <Link to="/services">Services</Link></button>
              <button className="Book Now"><a href="https://firststopbarbershop.glossgenius.com/">Book Now</a></button>
            </div>
          </div>
        </motion.div>
      </Element>

      <Element name="services" className="section bg-white">
        <motion.div
          className="other-section"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={fadeInVariants}
        >
          <div className="text-content">
            <h1>SERVICES</h1>
            <p>We offer haircuts, facials, and more!</p>
            <div className="buttons">
              <button className="Services-button">
                <Link to="/services">Services</Link></button>
              <button className="Book Now"><a href="https://firststopbarbershop.glossgenius.com/">Book Now</a></button>
            </div>
          </div>
          <motion.img
            src="/Photo1.jpg"
            alt="services"
            className="section-image right"
            variants={fadeInVariants}
          />
        </motion.div>
      </Element>

      <Element name="book-appointment" className="section bg-gray-100">
        <motion.div
          className="other-section"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={fadeInVariants}
        >
          <motion.img
            src="/Photo3.jpeg"
            alt="appointments"
            className="section-image left"
            variants={fadeInVariants}
          />
          <div className="text-content">
            <h1>BOOK APPOINTMENT</h1>
            <p>Schedule your next visit with us!</p>
            <div className="buttons">
              <button className="Services-button">
              <Link to="/services">Services</Link></button>
              <button className="Book Now"><a href="https://firststopbarbershop.glossgenius.com/">Book Now</a></button>
            </div>
          </div>
        </motion.div>
      </Element>

      <Element name="about" className="section bg-white">
        <motion.div
          className="other-section"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={fadeInVariants}
        >
          <motion.img
            src="/barber3.jpg"
            alt="about"
            className="section-image right"
            variants={fadeInVariants}
          />
          <div className="text-content">
            <h1>ABOUT US</h1>
            <p>Learn more about our story.</p>
            <div className="buttons">
              <button className="Services-button">
              <Link to="/about">about</Link></button>
              <button className="Book Now"><a href="https://firststopbarbershop.glossgenius.com/">Book Now</a></button>
            </div>
          </div>
        </motion.div>
      </Element>

      <Element name="client-photos" className="section bg-gray-100">
        <motion.div
          className="client-other-section"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={fadeInVariants}
        >
          <div className="client-text-content">
            <h1>CLIENT PHOTOS</h1>
            <p>Check out our latest styles and happy customers!</p>
          </div>

          {/* Carousel for photos */}
          <Slider {...settings}>
            {photos.length > 0 ? (
              photos.map((photo) => (
                <div key={photo.id} className="photo-item">
                  <img
                    src={photo.imageUrl}
                    alt={photo.name || "Client Photo"}
                    className="client-photo"
                  />
                </div>
              ))
            ) : (
              <p>No photos available</p>
            )}
          </Slider>
        </motion.div>
      </Element>

      <Footer />
    </div>
  );
}

export default Homepage;