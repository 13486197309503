import Services from "./components/Services";
import Homepage from "./Homepage";
import {BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BookAppointment from "./components/Book-Appointment";
import About from "./components/About";


function App() {
    
  return ( 
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/book-appointment" element={<BookAppointment/>} />
        <Route path="/about" element={<About/>} />
      </Routes>
    </Router>
  );
}

export default App;