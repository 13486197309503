import Header from "./Header.js";
import Preloader from "./Preloader.js";
import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import Footer from "./Footer.js";



const About = () => {
    const [loading, setLoading] = useState(true);
    return (  
        <div>
            <Header/>
            <div className="about-page">
            {loading && <Preloader setLoading={setLoading} />}
            <Element name="home" className="section bg-gray-101"style={{ borderBottom: '3px solid rgb(102, 88, 10)'}}>
          <div className="home-section">
            <video
              autoPlay
              loop
              muted
              playsInline
              className="background-video"
            >
              <source src="/Alec The Barber.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="text-content">
              <h1>About Us</h1>
              <p>Your go-to spot for a fresh cut and style!</p>
              <div className="buttons">
                <button className="Services-button">
                  <Link to="/">Home</Link>
                </button>
                <button className="Book Now">
                  <Link to="/Services">Services</Link>
                </button>
              </div>
            </div>
          </div>
        </Element>
            <div className="about-text-content">
                <div className="about-text">
                <p>Welcome to my studio! I'm Alec Heim, a 29-year old barber with over 12 years of experience. I've been cutting hair since I was a sophomore in high school, and it's been my passion ever since. Growing up, my mom was my biggest inspiration she's a talented barber, and watching her work sparked my love for the craft.</p>
                <p>After years of honing my skills and industry, I'm proud to have opened my own studio. My goal is to provide top-quality cuts and a personalized experience in a welcoming, laid-back environment. Whether you're looking for a classic look or something fresh, I'm here to bring your vision to life.</p>
                <div className="about-images">
                <img src="./Photo4.jpg" alt="barber"></img>
                </div>
                </div>
            </div>
            <Footer/>
            </div>
            
        </div>
    );
}
 
export default About;