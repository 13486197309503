import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Header from "./Header";
import Preloader from "./Preloader";
import { Element } from "react-scroll";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const fadeInVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const Services = () => {
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch("https://decisive-health-361bf7e6f0.strapiapp.com/api/services?populate=Category");
        const data = await response.json();
        
        if (data?.data) {
          setServices(data.data);
        } else {
          console.error("Unexpected API response:", data);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
  }, []);

  
  const groupedServices = services.reduce((acc, service) => {
    const category = service.Category && Array.isArray(service.Category) && service.Category[0]?.Name
      ? service.Category[0].Name
      : "Other Services";  
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(service);
    return acc;
  }, {});


  return (
    <div className= "menu-page">
      <Header />
      {loading && <Preloader setLoading={setLoading} />} {/* Show preloader while loading */}
      <Element name="home" className="section bg-gray-101"style={{ borderBottom: '3px solid rgb(102, 88, 10)'}}>
        <motion.div
          className="home-section"
          initial="visible"
          whileInView="visible"
          viewport={{ once: true}}
          variants={fadeInVariants}
        >
           <video 
           autoPlay 
           loop 
           muted 
           playsInline 
           className="background-video"
          >
            <source src="/Alec The Barber.mp4" type="video/mp4" />
              Your browser does not support the video tag.
          </video>
          <div className="text-content">
            <h1>Our Services</h1>
            <p>Your go-to spot for a fresh cut and style!</p>
            <div className="buttons">
              <button className="Services-button">
              <Link to="/">Home</Link></button>
              <button className="Book Now">
              <Link to="https://firststopbarbershop.glossgenius.com/">Book Now
              </Link>
              </button>
            </div>
          </div>
        </motion.div>
      </Element>
      <Element name="services" className="services-category-container">
        <div className="category-section-container">
        {Object.keys(groupedServices).length > 0 ? (
          Object.keys(groupedServices).map((category) => (
            <div key={category} className="category-section">
              <h1 className="category-header">{category}</h1> {/* Display category header */}
              <div className="services-list">
                {groupedServices[category].map((service) => (
                  <motion.div
                    key={service.id}
                    className="service-card"
                    variants={fadeInVariants}
                  >
                    <div className="service-card-content">
                    <h2 className="service-name">{service.Name || "No name available"} - ${service.Price}</h2>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <p>Loading services...</p>
        )}
        </div>
      </Element>
      <Footer/>
    </div>
  );
};

export default Services;