import React, { useEffect, useState } from "react";

const Preloader = () => {
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setFadeOut(true); // Start fade-out animation
      setTimeout(() => setLoading(false), 500); // Wait for animation to complete before removing
    }, 3000); // Adjust delay as needed
  }, []);

  return loading ? (
    <div className={`fs-preloader ${fadeOut ? "fs-preloader-fadeout" : ""}`}>
      <div className="fs-preloader-logo">
        <img src={"Logo.webp"} alt="First Stop Logo"/>
        <div className="fs-loading-dots">
            <span></span><span></span><span></span>
        </div>
      </div>
    </div>
  ) : null;
};

export default Preloader;