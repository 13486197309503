import React, { useState, useEffect } from 'react'; 
import { Link } from 'react-scroll';
import { useLocation, Link as RouterLink } from 'react-router-dom'; // Import RouterLink for routing when not on the homepage
import { FiMenu, FiX } from 'react-icons/fi'; // Import icons for the hamburger menu

const Header = () => {
  const location = useLocation();
  const isHomepage = location.pathname === '/';  
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // State for mobile menu toggle

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
    
    const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    };

  return (
    <nav className={`Header-nav ${isScrolled ? "shrink" : ""}`}>
      <div className="menu-toggle" onClick={toggleMenu}>
        {menuOpen ? <FiX /> : <FiMenu />}
      </div>
      <ul className={`nav-ul ${menuOpen ? "open" : ""}`}>
        <img src={"Logo.webp"} alt="First Stop Logo" className="logo" />
        <li>
          {isHomepage ? (
            <Link to="home" smooth={true} duration={500} onClick={() => setMenuOpen(false)}>
              Home
            </Link>
          ) : (
            <RouterLink to="/">Home</RouterLink>
          )}
        </li>
        <li>
          {isHomepage ? (
            <Link to="services" smooth={true} duration={500} onClick={() => setMenuOpen(false)}>
              Services
            </Link>
          ) : (
            <RouterLink to="/services">Services</RouterLink>
          )}
        </li>
        <li>
          {isHomepage ? (
            <Link to="book-appointment" smooth={true} duration={600} onClick={() => setMenuOpen(false)}>
              Book Appointment
            </Link>
          ) : (
            <RouterLink to="https://firststopbarbershop.glossgenius.com/">Book Appointment</RouterLink>
          )}
        </li>
        <li>
          {isHomepage ? (
            <Link to="about" smooth={true} duration={500} onClick={() => setMenuOpen(false)}>
              About Us
            </Link>
          ) : (
            <RouterLink to="/about">About Us</RouterLink>
          )}
        </li>
        {isHomepage && (
          <li>
            <Link to="client-photos" smooth={true} duration={500} onClick={() => setMenuOpen(false)}>
              Client Photos
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Header;